@use "colors";

:root {
  --material-transition-curve: cubic-bezier(0.4, 0, 0.2, 1);
}

.MuiDateRangeCalendar-root  {
  & > div:first-child {
    display: none;
  }
}

.half-day {
  background:white;
  background: linear-gradient(to right bottom, #2f3441 50%, white 50%);
}

.maplibregl-popup-content, .mapboxgl-popup-content {
  max-width: inherit;
}
